<template>

  <div
      style="width:100%;height:calc(100vh - 50px);position: absolute;left: 0;top:50px">
    <div class="loading" v-if="isLoading">
      <div class="blackUI"></div>
      <div class="loadingText">加载中...</div>
    </div>
    <div class="year" v-if="!isLogin">
      <Dropdown class="p-mr-3" v-model="currentYear" @change="loadProjectList"
                :options="years" option-label="name" option-value="value"></Dropdown>
      <SelectButton class="type-select" v-model="currFilter" :options="filter" optionLabel="name"
                    :multiple="true">
        <template #option="slotProps">
          <i :class="slotProps.option.icon"></i>
          <label style="margin-left:8px;">{{ slotProps.option.name }}</label>
        </template>
      </SelectButton>
    </div>
    <div id="label" onmouseleave="document.getElementById('label').style.display='none'">
      <div @click="selectProjType('进行中-生产')" class="p-flex-row" style="cursor:pointer;">
        <div
            style="float:left;width:24px;height:24px;background-color:rgba(18, 162, 93, 1);border-radius:50px;padding-top:3px;padding-left:6px;">
          <i style="color:#fff;font-size:9px;" class="iconfont icon-xiangmu1"></i>
        </div>
        <div :style="{'color':(currProjType['进行中-生产'])?'rgba(18, 162, 93, 1)':'rgba(0, 0, 0, 0.65)'}"
             style="line-height:24px;float:left;margin-left:10px;margin-bottom:14px;">进行中-生产
        </div>
      </div>
      <div @click="selectProjType('进行中-科研')" class="p-flex-row" style="cursor:pointer;">
        <div
            style="float:left;width:24px;height:24px;background-color:rgba(245, 187, 2, 1);border-radius:50px;padding-top:3px;padding-left:6px;">
          <i style="color:#fff;font-size:9px;" class="iconfont icon-xiangmu1"></i>
        </div>
        <div :style="{'color':(currProjType['进行中-科研'])?'rgba(18, 162, 93, 1)':'rgba(0, 0, 0, 0.65)'}"
             style="line-height:24px;float:left;margin-left:10px;margin-bottom:14px;">进行中-科研
        </div>
      </div>
      <div @click="selectProjType('已归档')" class="p-flex-row" style="cursor:pointer;">
        <div
            style="float:left;width:24px;height:24px;background-color:rgba(234, 84, 85, 1);border-radius:50px;padding-top:3px;padding-left:6px;">
          <i style="color:#fff;font-size:9px;" class="iconfont icon-xiangmu1"></i>
        </div>
        <div :style="{'color':(currProjType['已归档'])?'rgba(18, 162, 93, 1)':'rgba(0, 0, 0, 0.65)'}"
             style="line-height:24px;float:left;margin-left:10px;margin-bottom:0px;">已归档
        </div>
      </div>
    </div>
    <!--    <div v-if="isLoading" style="color:rgba(0, 0, 0, 0.45)">正在渲染地图，请稍后...</div>-->
    <div id="container" class="mapview" tabindex="0">
    </div>
    <!--  项目浮动窗口-->
    <OverlayPanel class="wy" ref="op" :breakpoints="{'960px': '75vw', '640px': '100vw'}"
                  :style="{width: '283px','min-height':'140px',padding:'0px'}" style="padding:0px;">
      <table border="0" cellpadding="0" cellspacing="0" style="width:100%;height:110px;margin:0px;">
        <tr style="height:45px;">
          <td style="padding:11px 0 11px 13px">
            <div
                :style="{backgroundColor:currProjFloatDialog.isArchive==0?(currProjFloatDialog.type.indexOf('科研/')!=-1?'rgba(255, 205, 66, 1)':'rgba(18, 162, 93, 1)'):'rgba(234, 84, 85, 1)'}"
                style="width:24px;height:24px;border-radius:50px;padding-left:6px;padding-top:3px;float:left;">
              <i style="color:#fff;font-size:12px;" class="iconfont icon-xiangmu1"></i>
            </div>
            <label style="color:rgba(48, 62, 101, 1);margin-left:8px;">地点：{{ currProjFloatDialog.province }}</label>
          </td>
          <td align="right" style="width:80px;padding: 11px 13px 11px 0">
            <Tag v-if="currProjFloatDialog.isArchive==0" value="进行中" class="tag"></Tag>
            <Tag v-if="currProjFloatDialog.isArchive==1" value="已归档" class="tag-archive"></Tag>
          </td>
        </tr>
        <tr style="height: 95px">
          <td v-if="currProjFloatDialog.isArchive==1" colspan="2" valign="top" class="content-project"
              style="padding: 11px 21px">
            实验效果：{{ currProjFloatDialog.effect }}
          </td>
          <td v-if="currProjFloatDialog.isArchive==0" colspan="2" valign="top" class="content-project"
              style="padding: 11px 21px">
            <b>项目名称：</b>{{ logining ? currProjFloatDialog.name : currProjFloatDialog.name.substr(0, 3) + '...' }}
            <br/>
            <b v-if="logining">项目成员：</b>

            <label v-if="logining" v-bind:key="item" v-for="(item, index) of JSON.parse(currProjFloatDialog.userlist)">
            <span v-if="item.is_leader == 1">
              <i v-tooltip="'组长'" class="pi pi-user"
                 style="color:rgba(82, 196, 26, 1);border-radius: 50px;border:1px solid rgba(183, 235, 143, 1);font-size:8px;background-color: rgba(246, 255, 237, 1);padding:2px;"></i>
              {{ item.name }}
            </span>
              <span v-else>
              {{ item.name }}
            </span>
              <span v-if="logining? (index &lt; JSON.parse(currProjFloatDialog.userlist).length-1):false">，</span>
            </label>
          </td>
        </tr>
      </table>
    </OverlayPanel>

    <!--  设备浮动窗口-->
    <OverlayPanel class="wy" ref="devFloat" :breakpoints="{'960px': '75vw', '640px': '100vw'}"
                  :style="{width: (currDevFloatDialog.dtype=='摄像头'?'400px':'283px'),'min-height':(currDevFloatDialog.dtype=='摄像头'?'260px':'140px')}">
      <div>
        <div class="Name">
          {{ currDevFloatDialog.dtype == '摄像头' ? '摄像头：' : '传感器：' }}
          {{ currDevFloatDialog.dname }}
        </div>
        <div v-if="currDevFloatDialog.dtype !='摄像头'" class="Content">
          <div class="p-col-6" style="position: relative"
               v-bind:key="item" v-for="(item,index) in currDevNewData">
            <div class="p-grid" style="height: 51px" :class="{'border-right':index % 2==0}">
              <div class="p-col-4" style="display: flex;justify-content: center;align-content: center">
                <i style="font-size:20px;margin-top:0.5rem" :class="'iconfont ' + item.icon"></i>
              </div>
              <div class="p-col-8">
                <div>{{ item.newValue }} {{ item.valueUnit }}</div>
                <div>{{ item.cname }}</div>
              </div>
            </div>
          </div>
          <div v-if="!hasNewData" style="line-height:90px;text-align:center;width:100%;color:rgba(0, 0, 0, 0.45);">
            最近6小时内无新数据
          </div>
          <div v-if="isLoadingNewData" style="line-height:90px;text-align:center;width:100%;color:rgba(0, 0, 0, 0.45);">
            正在加载最新数据...
          </div>
        </div>
        <div v-if="currDevFloatDialog.dtype =='摄像头'" class="Content">
          <img
              :src="$serverUrl + '/device/getLatestWebCamImage?pid=' + currDevFloatDialog.pid + '&did=' + currDevFloatDialog.did + '&token=' + $getToken()"
              style="width:100%;height:220px;"/>
        </div>
      </div>
    </OverlayPanel>

    <!--  <Dialog v-model:visible="isShowVideo" :style="{width: '800px','padding':'0px','overflow':'hidden'}"-->
    <!--          :header="currDevFloatDialog.dname" :modal="true" class="p-fluid"-->
    <!--          @hide="hideDialog">-->
    <!--    <iframe style="width:100%;height:420px;border: none;" src="/ys/player.html"></iframe>-->
    <!--  </Dialog>-->

    <ESVideo v-if="videoDisplay" ref="ysVideo" v-on:close="closeVideo"></ESVideo>

    <Toast/>
  </div>
</template>

<script>
import ESVideo from '@/views/modules/data/overview/ESVideo';

export default {
  components: {
    ESVideo,
  },
  props: {
    isLogin: {
      default: false,
    },
  },
  data() {
    return {
      videoDisplay: false,
      filter: [
        {name: '传感器', code: '传感器', icon: 'iconfont icon-chuanganqi2'},
        {name: '摄像头', code: '摄像头', icon: 'iconfont icon-shexiangtou'},
        {name: '项目', code: '项目', icon: 'iconfont icon-xiangmu1'},
      ],
      currFilter: [
        {name: '传感器', code: '传感器', icon: 'iconfont icon-chuanganqi2'},
        {name: '摄像头', code: '摄像头', icon: 'iconfont icon-shexiangtou'},
        {name: '项目', code: '项目', icon: 'iconfont icon-xiangmu1'},
      ],
      isLoading: true,
      projectList: [],
      deviceList: [],
      points: [],
      currProjFloatDialog: {},
      currDevFloatDialog: {},
      isLoadingNewData: true,
      currDevNewData: [],
      hasNewData: true,
      isShowVideo: false,
      currProjType: {
        '进行中-科研': true,
        '进行中-生产': true,
        '已归档': true,
      },
      currentYear: null,
      years: [
        {name: '全部', value: '全部'},
      ],
      projectDeviceList: [],
      logining: false,
      chatDisplay: false,
    };
  },
  mounted() {
    let arr = [];
    localStorage.setItem('news', JSON.stringify(arr));
    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    if (userinfo && userinfo.token && userinfo.token.token) {
      this.logining = true;
    } else {
      this.logining = false;
    }
    let currenyear = new Date().getFullYear();
    this.currentYear = currenyear;
    if (this.isLogin) {
      this.currentYear = '全部';
    }
    if (this.logining) {
      this.$http('project/year', 'get', null, (res) => {
        res.data.forEach(item => {
          this.years.push({name: item.name + '年度', value: Number(item.name)});
        });
      });
    }

    let me = this;
    window['hide'] = function(what) {
      if (what == 0) {
        me.$refs.op.toggle(event);
      } else {
        me.$refs.devFloat.toggle(event);
      }
    };
    window['viewProject'] = function(id) {
      for (let i in me.projectList) {
        let proj = me.projectList[i];
        if (proj.id == id) {
          me.currProjFloatDialog = proj;
          break;
        }
      }
      me.$refs.op.toggle(event);
    };
    window['goDevDetail'] = function(belongProjId) {
      if (me.currDevFloatDialog.dtype == '摄像头') {
        me.openVideo();
      } else {
        window.goProjDetail(belongProjId);
      }
    };
    window['goProjDetail'] = function(id) {

      if (localStorage.getItem('userinfo') && JSON.parse(localStorage.getItem('userinfo')).token.token) {

        if (!id) {
          me.$toast.add({severity: 'warn', summary: '无项目信息！', detail: '该设备暂未关联任何项目！', life: 3000});
          return;
        }
        // 检查当前用户是否有此项目的权限
        me.$http('/proj_user_ref/checkAuthority', 'get', {projId: id, userId: me.$getUser().user.id}, (resp) => {
          if (resp.data === true) {
            window['nav'] = true;
            me.$router.push({path: '/data/detail/dtzz', query: {projId: id, from: '/dashboard'}});
          } else {
            me.$toast.add({severity: 'warn', summary: '无访问权限！', detail: '抱歉，您暂无访问此项目的权限！', life: 3000});
          }
        });
      }
    };
    window['viewDevice'] = function(id) {
      event.preventDefault();
      me.hasNewData = true;
      me.isLoadingNewData = true;
      me.currDevNewData = null;
      for (let i in me.deviceList) {
        let dev = me.deviceList[i];
        if (dev.tableid == id) {
          me.currDevFloatDialog = dev;
          let items = id.split('|');
          let pid = items[0];
          let did = items[1];
          me.$http('/device/getDevNewData', 'get', {'pid': pid, 'did': did}, (resp) => {
            me.currDevNewData = resp.data;
            me.isLoadingNewData = false;
            if (!me.currDevNewData || Object.keys(me.currDevNewData).length == 0) {
              me.hasNewData = false;
            }
          });
          break;
        }
      }
      me.$refs.devFloat.toggle(event);
    };

    this.loadProjectList();

    setTimeout(() => {
      document.querySelectorAll('[role=\'button\']').forEach((e) => {
        e.addEventListener('click', () => {

          let curr = e.getAttribute('aria-label');
          if (curr == '项目') {
            let stat = document.getElementById('label').style.display;
            if (stat == 'none' || !stat) {
              document.getElementById('label').style.display = 'block';
            } else {
              document.getElementById('label').style.display = 'none';
            }
          } else if (curr == '传感器' || curr == '摄像头') {
            this.renderMap();
          }
        });
      });
    }, 1000);

  },
  methods: {

    hasZuzhang(list) {
      for (let i in list) {
        if (list[i].is_leader == 1) {
          return true;
        }
      }
      return false;
    },

    closeVideo() {
      console.log('销毁了。。。。');
      this.videoDisplay = false;
      this.$nextTick();
    },
    selectProjType(type) {
      if (this.currProjType[type]) {
        this.currProjType[type] = false;
      } else {
        this.currProjType[type] = true;
      }

      this.renderMap();
    },
    openVideo() {

      let me = this;

      // 获取摄像头参数
      this.$http('/devconf/getConf', 'get', {
        devPid: this.currDevFloatDialog.pid,
        devDid: this.currDevFloatDialog.did,
      }, (resp2) => {
        // 获取token
        this.$http('/device/getYsToken', 'get', {
          devPid: this.currDevFloatDialog.pid,
          devDid: this.currDevFloatDialog.did,
        }, (resp) => {
          this.videoDisplay = true;
          this.$nextTick(() => {
            me.$refs.ysVideo.show(resp.data[0], this.lookupConfItem(resp2.data, 'serial_num'),
                me.currDevFloatDialog.dname + '（' + me.currDevFloatDialog.pid + '-' + me.currDevFloatDialog.did + '）');
          });
        });
      });
    },
    lookupConfItem(items, name) {
      if (items) {
        for (let i in items) {
          let item = items[i];
          if (item.name == name) {
            return item;
          }
        }
      }
      return null;
    },
    loadProjectList() {
      let _this = this;
      this.$http('/project/allWithMember', 'get', {
        year: this.currentYear === '全部' ? null : this.currentYear,
      }, (resp) => {
        this.projectList = resp.data;
        this.projectDeviceList = [];
        if (localStorage.getItem('userinfo') && JSON.parse(localStorage.getItem('userinfo')).token.token) {
          resp.data.forEach(project => {
            if (project.deviceList && project.deviceList.records.length > 0) {
              console.log(project.deviceList.records);
              project.deviceList.records.forEach(dev => {

                if (dev.bizDstatus === 'Working') {
                  let point = dev.fullposi.split(',');
                  let projectToDevice = {
                    type: dev.dtype == '摄像头' ? 'v' : 'd',
                    pro: {
                      Q: project.positionLat,
                      R: project.positionLon,
                    },
                    dev: {
                      Q: Number(point[1]),
                      R: Number(point[0]),
                    },
                  };
                  _this.projectDeviceList.push(projectToDevice);
                }
              });
            }
          });
        }
        this.loadDeviceList();
      });
    },
    loadDeviceList() {
      if (this.logining) {
        this.$http('/device/all', 'get', null, (resp) => {
          if (localStorage.getItem('userinfo') && JSON.parse(localStorage.getItem('userinfo')).token.token) {
            this.deviceList = resp.data;
          }
          this.renderMap();
        });
      } else {
        this.renderMap();
      }
    },

    renderMap() {
      let projectIdList = [];
      this.projectList.forEach(item => {
        projectIdList.push(item.id);
      });



      this.points = [];

      let currMap = {};
      this.currFilter.forEach((e) => {
        currMap[e.name] = true;
      });

      // 数据预处理
      for (let i in this.projectList) {
        let proj = this.projectList[i];
        proj.dataType = 'project';

        if (proj.type.indexOf('生产') != -1 && proj.isArchive == 0) {
          if (this.currProjType['进行中-生产']) {
            this.points.push(proj);
          }
        } else if (proj.type.indexOf('科研') != -1 && proj.isArchive == 0) {
          if (this.currProjType['进行中-科研']) {
            this.points.push(proj);
          }
        } else {
          if (this.currProjType['已归档']) {
            this.points.push(proj);
          }
        }
      }

      // 添加设备
      for (let i in this.deviceList) {

        let dev = this.deviceList[i];
        if (projectIdList.includes(dev.projectId)) {
          dev.dataType = 'device';
          if (dev.dtype == '摄像头') {
            if (currMap['摄像头']) {
              this.points.push(dev);
            }
          } else {
            if (currMap['传感器']) {
              this.points.push(dev);
            }
          }
        }
      }

      this.$axios.get('/conf/get', {params: {codes: ['dashboard.map.center','dashboard.map.zoom_level'].toString()}}).then(res => {


          let AMap = window['AMap'];

      let _this = this;
      let DistrictSearch = new AMap.DistrictSearch({level: 'country', extensions: 'all'});
      DistrictSearch.search('中国', function(status, result) {
        let ChinaBorder = Object.assign([], result.districtList[0].boundaries);

        let map = new AMap.Map('container', {
          mapStyle: 'amap://styles/706ebe0d9d517a7316e132d2790739e6', //设置地图的显示样式
          resizeEnable: true,
          center: JSON.parse(res.data['dashboard.map.center']),
          layers: [
            new AMap.TileLayer.RoadNet(),
            new AMap.TileLayer.Satellite(),
          ],
          zoom: parseInt(res.data['dashboard.map.zoom_level']),
        });

        let outer = [
          new AMap.LngLat(-360, 90, true),
          new AMap.LngLat(-360, -90, true),
          new AMap.LngLat(360, -90, true),
          new AMap.LngLat(360, 90, true),
        ];

        let pathArray = [
          outer,
        ];
        pathArray.push.apply(pathArray, ChinaBorder);

        let b = new AMap.Polygon({
          path: pathArray,
          strokeWeight: 2,
          strokeColor: '#111111',
          fillColor: '#111111',
          fillOpacity: 0.6,
        });

        map.add(b);

        if (localStorage.getItem('userinfo') && JSON.parse(localStorage.getItem('userinfo')).token.token) {
          _this.projectDeviceList.forEach((item, index) => {
            if ((currMap['摄像头'] && item.type === 'v') || (currMap['传感器'] && item.type === 'd')) {
              let line = new AMap.Polyline({
                path: [
                  [item.pro.R, item.pro.Q],
                  [item.dev.R, item.dev.Q],
                ],
              });
              map.add(line);
            }
          });
        }

        map.on('complete', () => {

          let tool = null;
          let scale = null;
          if (!_this.isLogin) {
            map.plugin(['AMap.ToolBar'], function() {
              //加载工具条
              tool = new AMap.ToolBar({
                position: 'RB',
                offset: new AMap.Pixel(40, 60),
              });
              map.addControl(tool);
            });

            map.plugin(['AMap.Scale'], function() {
              scale = new AMap.Scale({
                position: 'RB',
                offset: new AMap.Pixel(35, 20),
              });
              map.addControl(scale);
            });
          }

          // 还原地图状态
          if (window['nav'] && window['zoom'] && window['center']) {
            window['nav'] = false;
            map.setZoom(window['zoom']); //设置地图层级
            map.setCenter(window['center']); //设置地图中心点
          }
        });

        // 保存地图的最后状态，在后面需要的时候还原状态
        map.on('moveend', () => {
          window['zoom'] = map.getZoom();
          window['center'] = map.getCenter();
        });

        let markers = [];
        for (let i = 0; i < _this.points.length; i += 1) {
          let curr = _this.points[i];

          // 渲染项目
          if (curr.dataType == 'project') {
            markers.push(new AMap.Marker({
              position: [curr.positionLon, curr.positionLat],
              content:
                  '<div onclick=\'window.goProjDetail(' + curr.id + ')\' onmouseenter=\'window.viewProject(' + curr.id +
                  ')\'  onmouseleave=\'hide(0)\' style=\'background-color: ' +
                  (curr.isArchive == 0 ? (curr.type.indexOf('科研/') != -1
                      ? 'rgba(255, 205, 66, 1)'
                      : 'rgba(18, 162, 93, 1)') : 'rgba(234, 84, 85, 1)')
                  +
                  '; height: 36px; width: 36px; border: 4px solid #fff; box-shadow: 1px 3px 16px 0px rgba(23, 54, 73, 0.3);border-radius: 50px;padding-left:7px;padding-top:6px;\'>' +
                  '<i class=\'iconfont icon-xiangmu1\' style=\'color:white;font-size:14px;\'></i></div>',
              offset: new AMap.Pixel(-15, -15),
            }));
          } else if (curr.dataType == 'device') {
            console.log(curr);
            markers.push(new AMap.Marker({
              position: [curr.longtitude, curr.latitude],
              content:
                  '<div onclick=\'window.goDevDetail(' + curr.projectId + ')\' onmouseenter=\'window.viewDevice("' +
                  curr.tableid +
                  '")\' onmouseleave=\'hide(1)\' style=\'background-color: rgba(255, 205, 66, 1); height: 36px; width: 36px; border: 4px solid #fff; box-shadow: 1px 3px 16px 0px rgba(23, 54, 73, 0.3);border-radius: 50px;padding-left:7px;padding-top:6px;\'>' +
                  '<i class=\'iconfont ' + (curr.dtype == '摄像头' ? 'icon-shexiangtou' : 'icon-chuanganqi2') +
                  '\' style=\'color:white;font-size:14px;\'></i></div>',
              offset: new AMap.Pixel(-15, -15),
            }));
          }
        }

        let count = markers.length;
        new AMap.MarkerClusterer(map, markers, {
          gridSize: 80,
          maxZoom: 16,
          renderClusterMarker: function(context) {
            // var factor = Math.pow(context.count / count, 1 / 18);
            let div = document.createElement('div');
            // var Hue = 180 - factor * 180;
            div.style.backgroundColor = 'rgba(0, 130, 117, 1)';
            let size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
            div.style.width = div.style.height = size + 'px';
            div.style.border = 'solid 4px #fff';
            div.style.borderRadius = size / 2 + 'px';
            div.style.boxShadow = '1px 3px 16px 0px rgba(23, 54, 73, 0.3)';
            div.innerHTML = context.count;
            div.style.lineHeight = (size - 6) + 'px';
            div.style.color = '#fff';
            div.style.fontSize = '24px';
            div.style.textAlign = 'center';
            context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
            context.marker.setContent(div);
          },
        });

        _this.isLoading = false;
      });
        });

    },
  },
};
</script>

<style scoped>
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

.blackUI {
  width: 100%;
  height: 100%;
  background: #111111;
  opacity: 0.5;
}

.loadingText {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  position: absolute;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  top: calc(50% - 25px);
  left: 0;
}

.year {
  position: absolute;
  right: 1rem;
  top: 30px;
  display: flex;
  z-index: 100;
}

.type-select >>> .p-component:not(.p-disabled):not(.p-highlight):hover {
  background: #ffffff !important;
  color: rgba(18, 162, 93, 1) !important;
}

.type-select >>> .p-component:not(.p-highlight) {
  border-color: #ced4da;
}

/*.type-select >>> .p-component:first-child{*/
/*  border-right: none!important;*/
/*}*/


.type-select >>> .p-button.p-highlight {
  background: #FFFFFF !important;
  color: rgba(18, 162, 93, 1) !important;
  border: 1px solid rgba(18, 162, 93, 1);
}

#label {
  position: absolute;
  display: none;
  padding: 1rem;
  border-radius: 4px;
  top: 67px;
  right: 1rem;
  z-index: 109;
  background-color: #FFFFFF;
  box-shadow: 1px 3px 16px 0px rgba(23, 54, 73, 0.3);
}

.tag {
  border-radius: 50px;
  background-color: rgba(18, 162, 93, 0.1);
  color: rgba(18, 162, 93, 1);
}

.tag-archive {
  background-color: rgba(251, 105, 93, 0.1);
  border-radius: 50px;
  color: rgba(234, 84, 85, 1);
}

.border-right:before {
  content: '';
  position: absolute;
  bottom: auto;
  right: 2px;
  top: 10px;
  height: 30px;
  width: 1px;
  background-color: #e9e9e9;
}


.Name {
  line-height: 20px;
  height: 20px;
  margin: 13px 21px;
}

.content-project {
  background: #F7F9FA;;
  width: 100%;
  margin: 0;
  border-radius: 0px 0px 8px 8px;
}

.Content {
  background: #F7F9FA;;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  border-radius: 0px 0px 8px 8px;
}

.mapview {
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: none;
}

html, body, #container {
  height: 100%;
  width: 100%;
}

.input-card {
  width: 25rem;
}

.input-card .btn {
  width: 7rem;
  margin-right: .7rem;
}

.input-card .btn:last-child {
  margin-right: 0;
}

</style>

<style lang="scss">
.wy .p-overlaypanel-content {
  padding: 0 !important;
}
</style>
